@import '~style/common';

.ShareButton {
  @include Font-XS-Text;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: $metrics-l;
  margin-bottom: $metrics-xs;
  margin-left: 0;
  margin-right: $metrics-xs;
  color: $color-white;
  padding: 6px;
  cursor: pointer;
  transition-duration: 0.2s;
  outline: none;
  font-weight: 500;

  @include media-small-desktop {
    padding: 0 6px;
    margin-right: 0;
  }
}

.ShareButton:hover {
  filter: brightness(90%);
}

.ButtonText {
  padding: 0 3px;
}

.Facebook {
  background-color: $color-facebook;
}

.Twitter {
  background-color: $color-twitter;
}

.Linkedin {
  background-color: $color-linkedin;
  margin-right: 0;
}
