@import '~style/common';

.MainContainer {
  @include hover-opacity;
  width: 100%;
  background-color: $color-secondary-lighten;
  position: relative;
  margin-top: $metrics-xl;
  margin-bottom: $metrics-l;

  @include media-tablet {
    @include grid-column(12);
    margin-top: $metrics-xxl;
    margin-bottom: $metrics-xs;
  }

  @include media-small-desktop {
    margin-top: $metrics-l;
    margin-bottom: $metrics-s;
  }
}

.MainHeading {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-S;
  float: none;
  padding-top: $metrics-xxl + $metrics-m;
  padding-bottom: $metrics-m;

  @include media-tablet {
    @include grid-column(10);
    float: none;
  }

  @include media-small-desktop {
    @include grid-column(5);
    @include grid-push(1);
    float: none;
    padding: $metrics-l 0;
  }
}

.SmallText {
  @include grid-column(3, $mobile-grid);
  @include Font-Body;
  float: none;
  font-weight: 500;
  padding-bottom: $metrics-m;

  @include media-tablet {
    @include grid-column(12);
    float: none;
  }

  @include media-small-desktop {
    @include grid-column(3);
    @include grid-push(1);
  }

  @include media-large-desktop {
    @include grid-column(2);
    @include grid-push(1);
  }

  .GreyLine {
    display: inline-block;
    height: 100%;
    width: $metrics-s;
    border-bottom: 1px solid $color-secondary-opacity-10;
    margin-bottom: 5px;
    margin-right: $metrics-xs;
  }
}
.ImageContainerIE {
  overflow: visible;
}

.ImageContainer {
  width: 100%;
  height: 450px;
  position: absolute;
  top: -145px;
  left: 0;
  overflow-x: hidden;
  pointer-events: none;

  @include media-small-desktop {
    top: -120px;
  }

  @include media-regular-desktop {
    top: -100px;
  }
}

.Image {
  width: 480px;
  float: none;
  position: absolute;
  left: 40%;
  top: 0;

  @include media-tablet {
    left: 20%;
  }

  @include media-small-desktop {
    top: 10%;
    left: 50%;
    width: 430px;
  }

  @include media-regular-desktop {
    width: 30vw;
    max-width: 510px;
  }
}

.ImageContainerIE {
  width: 100%;
  height: 110%;
  overflow: visible;
}

.SidePromoText {
  @include Font-Body;
  font-weight: 500;
  position: absolute;
  left: $metrics-s;
  top: 2 * $metrics-m;
  width: $metrics-xxl;
  height: $metrics-m;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-primary;
  color: $color-white;

  @include media-small-desktop {
    left: auto;
    right: $metrics-s;
    top: $metrics-s;
  }
}
