@import '~style/common';

.Container {
  display: flex;
  padding-bottom: $metrics-m;

  @include media-tablet {
    padding-bottom: $metrics-l;
  }

  @include media-small-desktop {
    padding-bottom: $metrics-xl - $metrics-s;
  }
}

.ContentContainer {
  @include grid-column(3, $mobile-grid);

  @include media-mobile {
    @include grid-column(7);
  }

  @include media-tablet {
    @include grid-column(8);
  }

  @include media-small-desktop {
    @include grid-column(5);
    @include grid-push(3);
  }

  @include Font-Body
}

.Name {
  @include Font-Head-M;
  line-height: $lh-head-s;
}

.Position {
  @include Font-Head-S;
  font-style: italic;
}

.Description {
  padding: $metrics-s 0;
}

.PictureContainer {
  width: 100%;

  margin-top: $metrics-s;

  @include media-mobile {
    @include grid-collapse;
    @include grid-column(5);
    margin-top: 0;
  }

  @include media-tablet {
    @include grid-collapse;
    @include grid-column(4);
  }

  @include media-small-desktop {
    @include grid-collapse;
    @include grid-column(2);
  }
}
