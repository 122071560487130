@import '~style/common';

.Container {
  background-color: $color-white;
  overflow: hidden;
  padding: $metrics-extra-l $metrics-s $metrics-extra-l;
}

.Header {
  @include grid-column(3, $mobile-grid);
  margin-top: $metrics-s;
  color: $color-primary;

  @include media-tablet {
    @include grid-column(12);
    margin-top: $metrics-m;
  }

  @include media-regular-desktop {
    margin-top: $metrics-l;
  }

  @include  media-large-desktop {
    margin-top: $metrics-l + 15px;
  }
}

.Buttons {
  z-index: 1;
  position: absolute;
  display: flex;
  margin-top: $metrics-extra-l/2;
  width: 100%;
  justify-content: space-between;
  top: 150px;
  @include media-mobile {
    top: $metrics-extra-l/2;
  }

  @include media-tablet {
    position: static;
    padding: 0;
    width: 150px;
  }
}

.Button {
  border-color: $color-primary
}

.SlideContent {
  position: relative;
}

.Layout {
  position: relative;
}
.TitleWithButtonWrapper{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &__ButtonPosition{
    margin-top: 10px;
    margin-bottom: 30px;
  }
}
