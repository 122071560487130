@import '~style/common';

.AssetContainer {
  height: 100%;
  width: 100%;
}

.BackgroundAsset {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Container {
  display: flex;
  flex-wrap: wrap;
  padding-top: $metrics-s;
  padding-bottom: $metrics-m;

  @include media-small-desktop {
    padding-top: $metrics-l;
  }
}

.Header {
  @include grid-column(12);
  float: none;
  margin-bottom: $metrics-s;
}

.MainHeading {
  @include Font-Head-M;
  margin-top: $metrics-xs;
  text-align: start;
  margin-bottom: $metrics-s;
  text-transform: uppercase;
  margin-left: 0;
  color: $color-white;

  @include media-small-desktop {
    @include Font-Head-N;
    padding-bottom: $metrics-m;
  }
}

.Column {
  @include grid-column(3, $mobile-grid);
  padding: $metrics-s 0;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  margin-left: 0;

  @include media-small-desktop {
    height: auto !important;
    margin-right: $metrics-extra-l/2;
  }
}

.ColumnHidden {
  height: $metrics-l;

  @include media-small-desktop {
    display: block;
    height: auto;
  }
}
.TitleWithButtonWrapper{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &__ButtonPosition{
    display: flex;
    justify-content: flex-end;
    min-width: 260px;
    margin-top: 10px;
    margin-bottom: 30px;
    padding-bottom: 60px;
    margin-right: 80px;
  }
}
.Button{
  font-weight: 400!important;
  background-color: white !important;
  color: $color-primary !important;
  &:hover{
    opacity: 0.6;
  }
}
.BottomContactButtonSection{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
