@import '~style/common';

.Navigator {
  position: absolute;
  pointer-events: none;
  margin-top: -$mobile-navbar-height;

  @include media-small-desktop {
    margin-top: -$desktop-navbar-height;
  }
}
