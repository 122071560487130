@import '~style/common';

.Container {
  position: relative;
  background-color: $color-white;
  overflow: hidden;
  padding: $metrics-s $metrics-s $metrics-extra-l;
}

.Layout {
  position: relative;
}

.Buttons {
  top: 80px;
}
