@import "~style/common";

.caseStudyHeader {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-M;
  text-align: center;
  padding: $metrics-l 0 $metrics-s 0;
  color: $color-primary;

  @include media-tablet {
    @include grid-column(12);
    @include Font-Head-L;
  }

  @include media-small-desktop {
    padding: $metrics-xl 0 $metrics-m;
  }
}
.caseStudySubHeader {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-S;
  text-align: center;
  padding-top: 10px;
  font-weight: 300;

  @include media-tablet {
    @include grid-column(12);
    @include Font-Head-S;
    font-weight: 300;
  }
  color: black;
}

.FiltersHeader {
  @include Font-Head-S;
  font-weight: 400;
  text-align: center;
  margin-bottom: $metrics-m;
  padding: 0 $metrics-s;
  font-size: $fs-body;

  @include media-mobile {
    font-size: $fs-head-s;
  }
}

.ArticleBoxesContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ArticleBox {
  display: flex;
  justify-content: space-between;
  max-width: 1240px;
}

.InnerSection {
  margin: 0;
  padding: 0;
}
