@import '~style/common';

.Arrow {
  border: solid $color-secondary;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 7px;
}

.ArrowButton {
  padding: 8px;
  background-color: transparent;
  border: none;
  outline: none;
  transition-duration: 0.2s;
}

.ArrowButton:hover {
  opacity: 0.7;
  cursor: pointer;
}

.Right {
  transform: rotate(-45deg) translate(-2px, -2px);
}

.Left {
  transform: rotate(135deg) translate(-2px, -2px);
}

.Up {
  transform: rotate(225deg) translate(-2px, -2px);
}

.Down {
  transform: rotate(45deg) translate(-2px, -2px);
}
