@import '~style/common';

.RelativeContainer {
  position: relative;
}

.Container {
  .Image, .Video, .placeHolder {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: contain;
    object-position: top right;
    opacity: 1;
    transition: opacity ease-out 1.5s;
  }

  .placeHolder {
    position: absolute;
  }

  @include media-tablet {
    .Image, .Video, .placeHolder {
      width: calc(100% + #{$grid-gutter});
      margin-left: -$grid-gutter / 2;
    }
  }
}

.Image, .Video {
  width: 100%;
  height: 100%;
}

.Image {
  background: no-repeat top right;
  background-size: contain;
  object-fit: contain;
}

.placeHolder {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: $color-secondary-opacity-20;
  min-height: $metrics-xxl;
  transition-duration: 0.5s;

  @include media-tablet {
    min-height: 420px;
  }
}
