@import '~style/common';

.MainContainer {
}

.AssetContainer {
  height: 100%;
  width: 100%;
}

.BackgroundAsset {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Column {
  @include grid-column(3, $mobile-grid);
  padding: $metrics-s 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  margin-left: 0;

  @include media-small-desktop {
    height: auto !important;
    margin-right: $metrics-extra-l/2;
  }
}

.ColumnHidden {
  height: $metrics-l;

  @include media-small-desktop {
    display: block;
    height: auto;
  }
}

.ColumnContent {
  margin-top: $metrics-s;
  ul {
    list-style: none;
    margin-left: $metrics-xs;
     
    li {
      margin: 0;
      margin-bottom: $metrics-xs;
      padding-left: 2*$metrics-xs;
      position: relative;    
      &:after {
        content: '';
        height: 6px;
        width: 6px;
        background: $color-white;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: $metrics-xs;
        left: 0;
      }
    }
  }
}

.Wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgba($color-white, 0.3);
  padding: 0 $metrics-xs $metrics-xs 0;
  @include media-tablet {
    width: 50%;
    border-bottom: none;
  }
}

.ArrowWrapper {
  padding-top: $metrics-s;
}

.CarouselArrow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  @include media-small-desktop {
    display: none;
  }
}

.CarouselArrow i {
  transition: all 0.45s ease-in;
  transform: rotate(45deg);
}

.CarouselArrowOpened i {
  transform: rotate(225deg);
}
