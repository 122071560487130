@import "~style/common";

.Container {
  padding-top: $metrics-xl;
}

.ArticleBox {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(6);
  }

  @include media-small-desktop {
    @include grid-column(4);
  }
}

.Partners {
  margin-top: $metrics-l + $metrics-s;
}
