@import '~style/common';

.Container {
  padding: $metrics-s 0;
  position: relative;

  @include media-tablet {
    padding: $metrics-xxl - $metrics-m 0;
  }
}

.Heading {
  @include grid-column(12);
  @include Font-Head-S;
  text-align: start;
  margin-bottom: $metrics-s;

  @include media-tablet {
    text-align: center;
  }
}

.SubHeading {
  @include grid-column(12);
  @include Font-Head-S;
  text-align: start;
  margin-bottom: $metrics-s;

  @include media-tablet {
    text-align: center;
    margin-bottom: $metrics-xl;
  }
}

.ColumnsContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.Column {
  padding: $metrics-s 0;
  text-align: start;

  @include grid-column(12);

  @include media-tablet {
    @include grid-column(6);
  }

  @include media-regular-desktop {
    @include grid-column(4);
  }

}
