@import '~style/common';

.Container {
  @include Font-Body;
  position: relative;
  width: 100%;
  margin-bottom: $metrics-s;
}

.Label {
  width: 100%;
  cursor: text;
  position: absolute;
  top: -$metrics-xs / 2;
  transition: all .2s ease-out;
}

.LabelInvalid {
  color: $color-danger;
}

.LabelActive {
  @include Font-XS-Text;
  top: -$metrics-s;
  opacity: 0.8;
}

.CheckIcon {
  position: absolute;
  right: $metrics-xs;
  font-size: 8px;
  opacity: 1;
  transition: opacity 0.3s;
}

.CheckIconHidden {
  opacity: 0;
}

.Input {
  @include Font-Body;
  min-width: 100%;
  height: 32px;
  max-width: 100%;
  overflow: hidden;
  border: none;
  border-bottom: 1px solid $color-secondary-opacity-20;
  outline: none;
  resize: none;
  box-sizing: content-box;
  margin-bottom: 0;

  &::-ms-clear {
    display: none;
  }
}

.Touched:invalid {
  border-bottom: 1px solid $color-danger;
}
