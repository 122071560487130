@import '~style/common';

.Container {
  background-image: url('/assets/cite.png');
  background-repeat: no-repeat;
  background-position: top left;
  padding: $metrics-s + 5px 0;
  color: $color-secondary;

  margin-top: $metrics-s;
  margin-bottom: $metrics-xs * 2;

  @include media-mobile {
    margin-top: $metrics-m;
    margin-bottom: $metrics-s;
  }

  @include media-small-desktop {
    margin-top: $metrics-l - $metrics-xs;
  }

  p {
    @include Font-Head-S;
  }

  cite {
    display: inline-block;
    @include Font-Body;
    font-style: normal;

    margin-top: $metrics-xs;

    @include media-small-desktop {
      margin-top: $metrics-s + $metrics-xs;
    }

    a {
      color: $color-secondary;
    }

    a:hover {
      color: $color-primary;
    }
  }

  cite a:hover:before {
    background-color: $color-primary-lighten-40;
  }

  cite:before {
    content: '';
    display: inline-block;
    width: $metrics-s;
    height: 2px;
    background-color: $color-secondary-opacity-10;
    margin-right: $metrics-s;
    position: relative;
    top: -$lh-body / 5;
  }
}
