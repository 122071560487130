@import '~style/common';

.Container {
  display: flex;
  padding-bottom: $metrics-sm;
  cursor: pointer;
}

.CheckBox {
  display: block;
  margin-right: 2*$metrics-xs;
  border-bottom: 2px solid $color-secondary-opacity-20;
  min-width: $metrics-sm;
  height: $metrics-s;
}

.NativeCheckBox {
  opacity: 0;
  cursor: pointer;
}

.Label {
  @include Font-XS-Text;
  cursor: pointer;
}

.CheckMark{
  position: relative;
  left: 16px;
  display: inline-block;

  &:after{
    content: '';
    display: block;
    width: 8px;
    height: 18px;
    border: solid $color-secondary;
    border-width: 0 2px 2px 0;
    transform: rotate(46deg);
  }
}
