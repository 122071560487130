@import '~style/common';

.Container {
  position: relative;
  background-color: $color-white;
  padding: $metrics-extra-l $metrics-s 0;
}

.cardsSections {
  display: flex;
  justify-content: space-between;
  margin: 0 -$metrics-xs;
  @include media-tablet {
    margin-bottom: $metrics-extra-l;
    width: 100%;
  }
}

.NavigationButtons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-small-desktop {
    display: none;
  }
}

.NavigationButton {
  border: none;
  width: 2*$metrics-xs;
  height: 2*$metrics-xs;
  border-radius: 50%;
  margin: $metrics-s;
  outline: none;
  background-color: $color-mischka;
}

.isActive {
  background: $color-primary;
  width: $metrics-s;
  height: $metrics-s;
}
.TitleWithButtonWrapper{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &__ButtonPosition{
    display: flex;
    justify-content: flex-end;
    min-width: 260px;
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 30px;
  }
}
.BottomContactButtonSection{
  margin-top: 40px;
  border-top: 2px solid rgba(0,0,0, 0.1);
  padding-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
