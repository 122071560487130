@import 'style/global';

.Line {
  height: 7px;
  width: 0;
  background-color:rgba($color-white, 0.1);
  visibility: hidden;
  transition: width .5s;
  margin-left: 2*$metrics-xs;
}

.Show {
  visibility: visible;
  width: 50px;
  .In {
    animation: fill;
    animation-timing-function: linear;
    height: 100%;
    background-color: $color-primary;
    animation-play-state: running;
  }
}

@keyframes fill {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.Paused {
  width: 50px;
  visibility: visible;
  .Out {
    height: 100%;
    background-color: $color-primary;
  }
}
