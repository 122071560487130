@import '~style/common';

.Container {
  @include grid-column(3, $mobile-grid);
  margin-top: $metrics-s;
  margin-bottom: $metrics-s;

  @include media-tablet {
    @include grid-column(12);
    margin-top: $metrics-l;
    margin-bottom: $metrics-l;
  }
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageNumber {
  @include Font-Head-S;
  font-weight: 300;
  cursor: pointer;
  @include hover-opacity;
  margin: $metrics-xs;

  @include media-tablet {
    @include Font-Body;
  }

  &.Active:hover {
    opacity: 1;
  }
}

.Active {
  font-weight: 500;
}
