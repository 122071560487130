@import '~style/global';

.SliderButtons {
  background-color: inherit;
  color: inherit;
  justify-content: space-between;
  
  @include media-small-desktop {
    width: 70%;
    margin: 0;
  }
}

.StageButton {
    background-color: transparent;
    color: inherit;
    border: none;
    margin: $metrics-xs $metrics-s;
    padding: 0 $metrics-xs;
    text-align: center;
    cursor: pointer;
    @include media-small-desktop {
      @include Font-Body;
      margin-top: $metrics-s;
    }
}

.Active {
  font-weight: bold;
}
