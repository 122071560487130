@import '~style/common';

.Container {
  @include grid-column(3, $mobile-grid);
  padding-top: $metrics-xl;
  padding-bottom: $metrics-xs;

  @include media-tablet {
    @include grid-column(12);
    padding-top: $metrics-xxl;
  }

  @include media-small-desktop {
    @include grid-column(10);
    @include grid-push(1);
    padding-top: $metrics-xxl + $metrics-l;
    padding-bottom: $metrics-m;
  }
}

.Title {
  @include Font-Head-M;
  margin-top: $metrics-s;

  @include media-mobile {
    @include Font-Head-N;
  }

  @include media-small-desktop {
    @include Font-Head-L;
    margin-top: $metrics-m;
    margin-bottom: $metrics-s;
  }
}
