@import '~style/common';

.Container {
  margin-top: $metrics-m;
}

.CarouselContainer {
   @include grid-column(3, $mobile-grid);
   @include media-tablet {
     @include grid-column(12);
   }
 }


.PartnerBox {
  @include grid-column(3, $mobile-grid);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  margin: $metrics-s auto;

  @include media-tablet {
    @include grid-column(12);
    margin: $metrics-m auto $metrics-l;
  }

  .PartnerLogoBox {
    text-align: center;
    height: $metrics-l;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;

    .Link:focus {
      outline: none;
    }

    .PartnerLogo {
      max-width: 75%;
      max-height: 100%;
    }
  }
}
