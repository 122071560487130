@import '~style/common';

$bottomLineTransitionTime: 1.2s;

.Container {
  position: relative;
  width: 100%;
  overflow-y: hidden;
  min-height: $metrics-l;
}

.isError {
  .SelectedValue {
    color: $color-danger;
  }
}

.NotClickable {
  pointer-events: none;
}

.SelectedValueContainer {
  @include Font-Body;
  background-color: $color-white;
  z-index: 1;
  position: relative;
  cursor: pointer;
  height: $metrics-l;
  line-height: $metrics-l;
  font-weight: 500;
  overflow: hidden;
  text-align: center;
}

.SelectedValueContainer:hover .SelectedValue, .SelectedValueContainer:hover .ArrowButton {
  opacity: 0.8;
}

.SelectedValue {
  transition: opacity 0.2s;
  width: 100%;
  height: 100%;
}

.List {
  z-index: 0;
  width: 100%;
  list-style: none;
  background-color: $color-white;
  padding: $metrics-xs 0;
  text-align: center;
}

.Value {
  @include Font-Body;
  @include hover-opacity;
  cursor: pointer;
  padding: $metrics-xs 0;
}

.NativeSelect {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ArrowButton {
  position: relative;
  left: $metrics-s/2;
  top: -2px;
  i {
    transform: rotate(45deg);
    transform-origin: 50% 25%;
    transition-duration: $opacity-transition-time;
    padding: 5px;
  }
}

.ArrowActive {
  i {
    transform: rotate(225deg);
    transform-origin: 50% 75%;
  }
}

.TopLine {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
}

.BottomLine {
  width: 100%;
  margin: 0;
  position: absolute;
  bottom: 0;
  transition-duration: $bottomLineTransitionTime;
  opacity: 1;
}

.Hidden {
  opacity: 0;
}
