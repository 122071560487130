@import '~style/global';

.Slider {
  background-color: $color-white;
  width: 100%;
  height: auto;
  padding: $metrics-s;
  padding-bottom: $metrics-l;

  @include media-small-desktop {
    padding-bottom: $metrics-xxl;
  }
}

.Buttons {
  margin-left: unset;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;

  @include media-small-desktop {
    flex-wrap: unset;
  }
}

.BranchButtons {
  > div  {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;

    @include media-tablet {
      align-items: flex-start;
    }
  }

  button {
    text-align: center;
    height: $metrics-xl;
    font-size: 1.7rem;

    @include media-tablet {
      text-align: unset;
    }
  }
}

.Carousel {
  height: 100%;
  top: 0;
  margin: 0 -10px;
  > div {
    overflow: visible;
  }
  @include media-tablet {
    margin: 0;
  }
}
.BottomContactButtonSection{
  margin-top: 40px;
  border-top: 2px solid rgba(0,0,0, 0.1);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  &__ButtonPosition{
    padding: 30px;
  }
}
