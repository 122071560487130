@import '~style/common';

@keyframes show {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes hide {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

.Wrapper {
  position: relative;
  height: auto;
  overflow: hidden;
}

.BackgroundVideo {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.ContainerWithImage{
  margin-top: $metrics-xl;
  display: flex;
  flex-direction: column;
}
.Container { 
  text-align: center;
  display: flex;
  flex-direction: column;
}


.Image{
  height: $metrics-l;
  width: $metrics-l;
  margin-bottom: $metrics-m;
  align-self: center;
  @include media-mobile {
    height: auto;
  width: auto;
  }
}
    
.MainHeadingContainer {
  @include grid-column(3, $mobile-grid);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  height: $metrics-xl;


  @include media-mobile {
    height: $metrics-l + $metrics-s;
  }
  
  @include media-tablet {
    @include grid-column(12);
    
    height: $metrics-xl + $metrics-s;
  }
  
  @include media-regular-desktop {
    height: $metrics-xxl + $metrics-s;
    margin-bottom: $metrics-s + $metrics-xs;
  }
}

.MainHeading {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-M;
  position: absolute;
  margin-left: 0;
  text-transform: uppercase;
  
  @include media-tablet {
    @include Font-Head-N;
    left: 50%;
    transform: translateX(-50%);
  }

  @include media-regular-desktop {
    @include Font-Head-L;
  }
}

.AnimateStartHeading {
  animation: show .5s linear;
}

.AnimateEndHeading {
  animation: hide .5s linear 2s;
}

.MainSmallText {
  @include Font-Body;
  font-size: $fs-head-s;
  @include grid-column(3, $mobile-grid);
  padding-top: $metrics-m;

  @include media-tablet {
    padding-top: 0;
    @include grid-column(6);
    @include grid-push(3);
  }
}

.MainLinksContainer {
  width: 100%;
  padding: $metrics-s 0;
  display: flex;
  justify-content: center;
  list-style-type: none;

  @include media-tablet {
    @include grid-column(8);
    @include grid-push(2);
    float: none;
    padding: $metrics-m 0 $metrics-xl;
  }  

  .MainLink {
    @include Font-Body;
    font-weight: 500;
    text-decoration: underline;
    margin: 0 $metrics-s 0;

    @include media-small-desktop {
      @include Font-Head-S;
      margin: 0 $metrics-s;
    }
  }
}

