@import "~bourbon-neat/core/neat";

//grid
$grid-column: 85px;
$grid-gutter: 30px;
$grid-width: 12 * $grid-column + 11 * $grid-gutter;
$neat-grid: (
  columns: 12,
  gutter: $grid-gutter,
);

//mobile-grid
$mobile-grid: (
  columns: 3,
  gutter: $grid-gutter,
);

//colors
$color-primary: #82BA26;
$color-primary-lighten-20: lighten($color-primary, 20%);
$color-primary-lighten-40: lighten($color-primary, 40%);

$color-secondary: #292a32;
$color-secondary-lighten: #f2f2f2;
$color-secondary-opacity-20: rgba($color-secondary, 0.2);
$color-secondary-opacity-10: rgba($color-secondary, 0.1);
$color-secondary-opacity-05: rgba($color-secondary, 0.05);

$color-white: #ffffff;
$color-white-opacity-50: rgba($color-white, 0.5);

$color-mischka: #dcdfe4;

$color-horizontal-line: rgba(205, 205, 205, 0.25);

$color-danger: #ff002a;
$color-success: #04b200;

$color-voice-recorder: #ff002a;

$color-facebook: #3b5998;
$color-twitter: #00aced;
$color-linkedin: #007fb1;
//imported
$color-accent: #45daff;
$color-grey: #f8f8f8;
$color-dark-grey: #8c8c8c;
$color-link: #66c6ed;
$color-pampas: #e9e5df;
$color-horizontal-line: rgba(205, 205, 205, 0.25);

//metrics
$metrics-xxxl: 500px;
$metrics-xxl: 200px;
$metrics-xl: 145px;
$metrics-extra-l: 100px;
$metrics-l: 85px;
$metrics-m: 60px;
$metrics-sm: 40px;
$metrics-s: 30px;
$metrics-xs: 10px;

//button-shadows
$shadow-10: 0 0 5px 0 rgba($color-secondary, 0.1);
$shadow-05: -5px 5px 10px 0 rgba($color-secondary, 0.05);

//button-rounding
$button-rounding: 40px;

//component-specific
$slider-button-gradient: linear-gradient(
  to bottom,
  rgba(242, 242, 242, 0) 0%,
  rgba(242, 242, 242, 0.75) 25%,
  rgba(242, 242, 242, 1) 40%,
  #f2f2f2 100%
);
$mobile-navbar-height: $metrics-l;
$desktop-navbar-height: 110px;
$logo-height: 41px;

$opacity-transition-time: 0.2s;

@mixin hover-opacity {
  transition: opacity $opacity-transition-time;

  &:hover {
    opacity: 0.8;
  }
}

//font-sizes
$fs-head-xl: 12rem;
$fs-head-l: 7.8rem;
$fs-head-n: 5rem;
$fs-head-m: 3.6rem;
$fs-head-s: 2.4rem;
$fs-body: 1.8rem;
$fs-xs-text: 1.3rem;

//line-heights
$lh-head-xl: 14rem;
$lh-head-l: 7.7rem;
$lh-head-n: 6.6rem;
$lh-head-m: 4.4rem;
$lh-head-s: 3.5rem;
$lh-body: 2.8rem;
$lh-xs-text: 2rem;

//fonts
@mixin Font-Head-XL {
  font-size: $fs-head-xl;
  line-height: $lh-head-xl;
  font-weight: 500;
  font-display: swap;
}

@mixin Font-Head-L {
  font-size: $fs-head-l;
  line-height: $lh-head-l;
  font-weight: 500;
  font-display: swap;
}

@mixin Font-Head-N {
  font-size: $fs-head-n;
  line-height: $lh-head-n;
  font-weight: 500;
  font-style: normal;
}

@mixin Font-Head-M {
  font-size: $fs-head-m;
  line-height: $lh-head-m;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin Font-Head-S {
  font-size: $fs-head-s;
  line-height: $lh-head-s;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin Font-Body {
  font-size: $fs-body;
  line-height: $lh-body;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

@mixin Font-XS-Text {
  font-size: $fs-xs-text;
  line-height: $lh-xs-text;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

@mixin sectionHeader {
  @include Font-Head-M;
  color: $color-primary;
  margin-top: $metrics-xs;
  text-align: start;
  margin-bottom: $metrics-s;
  margin-left: 0;
  text-transform: uppercase;

  @include media-small-desktop {
    @include Font-Head-N;
  }
}
