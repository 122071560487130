@import '~style/common';

.Buttons {
  z-index: 1;
  position: absolute;
  display: flex;
  
  width: 100%;
  justify-content: space-between;
  padding: 0;


  @include media-tablet {
    position: static;
    margin-top: $metrics-extra-l/2;
    width: $metrics-extra-l;
    padding: 0;
  }
}
  
.Button {
  border-color: $color-primary;
}
