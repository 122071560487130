@import '~style/common';

.Container {
  padding: 0;
  margin-bottom: $metrics-l;
  cursor: pointer;
  color: $color-secondary;
  @include hover-opacity;

  @include media-tablet {
    margin-bottom: $metrics-xs;
    margin-top: $metrics-l;
  }
}

.RelativeAssetContainer {
  @include media-less-than-tablet {
    @include grid-collapse;
  }

  position: relative;
  height: $metrics-xxl + $metrics-l;
}

.ImageContainer {
  height: 100%;
}

.Image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background: top right;
  background-size: cover;
}

.Tags {
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row;
  position: absolute;
  left: 0;
  bottom: 0;
}

.Title {
  @include Font-Head-S;
  padding: $metrics-s + $metrics-xs 0 $metrics-s 0;
}

.BottomText {
  @include Font-Body;
}
