@import '~style/common';

.MobileNav {
  display: flex;

  @include media-small-desktop {
    display: none;
  }
}

.DesktopNav {
  display: none;

  @include media-small-desktop {
    display: flex;
  }
}
