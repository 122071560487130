@import '~style/common';

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  color: $color-primary;
}

.ErrorCode {
  display: flex;
}

.Number {
  @include Font-Head-XL;
  pointer-events: none;
  margin-bottom: $metrics-s;
}

.Description {
  @include Font-Body;
  width: 80%;
  text-align: center;
  color: $color-secondary;
  @include media-small-desktop {
    opacity: 0;
    transition: opacity ease-out 1.5s;
  }
}

.Underlined {
  padding-bottom: $metrics-xs;
  @include media-tablet {
    border-bottom: 1px solid $color-secondary-lighten;
    padding-bottom: 10px;
  }
}

.UsefulLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include Font-Body;


  .LinksHeader {
    color: $color-secondary;
    margin: 20px 0 10px;
  }

  .Link {
    margin: 5px 0;
    padding: 0;
    @include hover-opacity;
    cursor: pointer;
    color: $color-primary;
    font-weight: 500;
  }

  @include media-tablet {
    flex-direction: row;
    opacity: 0;
    transition: opacity ease-out 1.5s;
    width: 100%;
    justify-content: space-around;

    .LinksHeader {
      margin: 2*$metrics-xs $metrics-s;
    }

    .Link {
      margin: 20px 0;
      padding-left: 20px;

      &:after {
        content: ".";
        margin-left: 20px;
      }

      &:last-of-type:after {
        content: "";
      }
    }
  }
}
