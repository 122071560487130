@import 'style/global';

.Slide {
  margin: 0 8%;
  text-align: center;
  margin: $metrics-l -10px;
  @include Font-XS-Text;
  @include media-small-desktop {
    margin: $metrics-l 0;
      @include Font-Body 
  }
}

.Square {
  width: 150px;
  height: 150px;
  background-color: $color-pampas;
  border-radius: $metrics-s;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-tablet {
    width: 250px;
    height: 250px;
  }
}

.CenteredSquare {
  background-color: $color-primary;
  position: absolute;
  right: 50%;
  transform: translateX(50%) rotate(45deg);
  z-index: 3;
  opacity: 1;
  font-size: $metrics-extra-l;
  color: $color-white;
  @include media-tablet {
    font-size: 2*$metrics-extra-l;
  }
}

.Button {
  border-color: $color-primary;
  transform: translateX(45px) rotate(135deg);
  cursor: pointer;
  outline: none;
  z-index: 10;
  @include media-tablet {
    transform: translateX(25px) rotate(135deg);
  }
}

.ButtonWrapper {
  padding: 0;
  position: relative;
  z-index: 10;
}

.LeftButton {
  transform: translateX(45px) rotate(135deg);
  @include media-tablet {
    transform: translateX(25px) rotate(135deg);
  }
}

.RightButton {
  transform: translateX(-45px) rotate(-45deg);
  @include media-tablet {
    transform: translateX(-25px) rotate(-45deg);
  }
}

.Wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0;
  position: relative;
  @include media-tablet {
    justify-content: center;
    padding: $metrics-s;
  }
}

.SlideNumber {
  margin: 0 auto;
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: $metrics-xxl;
}

.Subtitle {
  font-size: 18px;
  margin: $metrics-l $metrics-m;
  @include media-tablet {
    @include Font-Head-M;
  }
}

.Description {
  margin: $metrics-l auto;
  min-height: $metrics-xxl;
  @include media-small-desktop {
    width: 70%;
  }
}

.ButtonLink {
  height: $metrics-xl;
  border: 1px solid $color-accent;
  border-radius: $metrics-xs;
  background-color: $color-secondary;
  color: $color-white;
  padding: 0 $metrics-l;
  @include Font-Body;
  font-weight: bold;
  margin-bottom: $metrics-xl;
}
