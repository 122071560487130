@import '~style/common';

.MainContainer {
  width: 100%;
  background-color: $color-secondary-lighten;
  margin-top: 0;
  margin-bottom: $metrics-l;

  @include media-tablet {
    @include grid-column(12);
    margin-top: $metrics-l;
    margin-bottom: $metrics-xs;
  }

  @include media-small-desktop {
    margin-bottom: $metrics-s;
  }
}

.MainHeading {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-S;
  float: none;
  padding: $metrics-m 0 $metrics-s;

  @include media-tablet {
    @include grid-column(12);
    float: none;
  }

  @include media-small-desktop {
    @include grid-column(4);
    @include grid-push(1);
    float: none;
  }

  @include media-regular-desktop {
    @include grid-column(3);
    @include grid-push(1);
    float: none;
  }
}

.SmallDescription {
  @include grid-column(3, $mobile-grid);
  @include Font-Body;
  padding-bottom: $metrics-s;

  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(3);
    @include grid-push(1);
    padding-bottom: $metrics-m;
  }

	a:link, a:visited, a:focus, a:active {
    color: $color-secondary;
    outline: none;
    text-decoration: underline;
  }

  a:hover {
    color: $color-secondary-opacity-20;
  }
}

.Input {
  @include grid-column(3, $mobile-grid);
  margin-top: $metrics-s / 2;

  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(4);
    @include grid-push(1);
  }

  input {
    background-color: transparent;
  }
}

.Button {
  @include grid-column(3, $mobile-grid);
  float: none;
  margin-bottom: $metrics-m;

  @include media-mobile {
    max-width: $metrics-xxl + $metrics-m;
  }

  @include media-small-desktop {
    @include grid-column(2);
    margin-left: $metrics-s;
  }
}

.CheckBoxPanelVisible {
  @include grid-column(3, $mobile-grid);
  float: none;
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: 1;
  margin-bottom: $metrics-s;
  transform: translateX(0);
  pointer-events: auto;
  transition: all, 0.5s ease-out;
  transition-delay: 0.3s;

  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(10);
    @include grid-push(1);
  }
}

.CheckBoxPanelHidden {
  position: absolute;
  opacity: 0;
  transform: translateX(-25px);
  pointer-events: none;
}
