@import '~style/common';

.DefaultColors {
  background-color: $color-secondary;
  color: $color-white;
}

.LayoutStyle {
  padding: $metrics-xl 0;
}

.Description {
  width: 100%;
  @include Font-Body;
  margin-bottom: $metrics-l;

  @include media-tablet {
    @include grid-column(8);
    @include grid-push(2);
    margin-bottom: $metrics-l;
    text-align: center;
  }
}

.MainLinksContainer {
  width: 100%;
  float: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style-type: none;
  position: relative;

  @include media-tablet {
    @include grid-column(8);
    @include grid-push(2);
    justify-content: center;
    margin-top: $metrics-xs;
  }

  .LinkContainer {
    margin-bottom: $metrics-xs;
  }

  .MainLink, .BottomLink {
    @include Font-Body;
    font-weight: 500;
    text-decoration: underline;
    color: $color-white;
    cursor: pointer;
    &:hover {
      color: $color-white-opacity-50;
    }

    @include media-tablet {
      margin: $metrics-s;
    }
  }

  .BottomLink {
    font-weight: 300;
  }
}

.MainHorizontalLine {
  @include grid-column(3, $mobile-grid);
  margin-top: $metrics-s;
  margin-bottom: $metrics-s;

  @include media-tablet {
    @include grid-column(12);
    margin-top: $metrics-l;
    margin-bottom: $metrics-s;
  }
}

.LogoWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: $metrics-m;
  @include media-small-desktop {
    width: auto;
    position: absolute;
    left: 0;
    top: -30px;
  }
}

.Logo {
  width: 120px;
  height: 120px;
}

.BottomText {
  border-top: 1px solid $color-white-opacity-50;
  padding-top: $metrics-s;
  margin-top: $metrics-s;
  width: 100%;
  @include Font-Body;
  a:link, a:visited, a:focus, a:active {
    color: $color-white;
    outline: none;
    text-decoration: underline;
  }
  
  a:hover {
    color: $color-white-opacity-50;
  }
  
  @include media-tablet{
    @include grid-column(8);
    @include grid-push(2);
    text-align: center;
  }
}
