@import "~style/common";

.Container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column-reverse;

  padding-top: $metrics-s;
  padding-bottom: $metrics-l;

  @include media-tablet {
    padding-bottom: $metrics-extra-l;
  }

  @include media-small-desktop {
    flex-direction: row;
    padding-top: $metrics-extra-l;
  }
}

.ShareButtonsContainer {
  overflow: hidden;
  @include grid-column(3, $mobile-grid);

  margin-top: $metrics-s;

  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(2);
    @include grid-push(1);
  }
}

.ShareButtons {
  display: flex;

  @include media-small-desktop {
    flex-direction: column;
  }
}

.Content {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(12);
  }

  @include media-small-desktop {
    @include grid-column(7);
    margin-top: -$metrics-l + $metrics-s;
  }
}
