@import '~style/common';

.Link {
  cursor: pointer;

  &:hover {
    color: $color-secondary-opacity-20;
  }
}

.Active {
  font-weight: 500;
}
