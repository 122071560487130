@import "~style/common";

.BlogHeader {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-M;
  text-align: center;
  padding: $metrics-l 0 $metrics-s 0;
  color: $color-primary;

  @include media-tablet {
    @include grid-column(12);
    @include Font-Head-L;
  }

  @include media-small-desktop {
    padding: $metrics-xl 0 $metrics-m;
  }
}

.BlogSubHeader {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-S;
  text-align: center;
  padding-top: 10px;
  font-weight: 300;

  @include media-tablet {
    @include grid-column(12);
    @include Font-Head-S;
    font-weight: 300;
  }
  color: black;
}

.ArticleBoxesContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.ArticleBox {
  @include grid-column(3, $mobile-grid);

  @include media-tablet {
    @include grid-column(6);
  }

  @include media-small-desktop {
    @include grid-column(4);
  }
}

.InnerSection {
  margin: 0;
  padding: 0;
}
