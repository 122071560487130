@import '~style/common';

.Heading {
  @include Font-Head-S;
  margin: $metrics-s 0 $metrics-s 0;
  text-align: start;
  text-transform: uppercase;
  @include media-small-desktop {
    @include Font-Head-M;
  }
}

.Container {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: $metrics-s;
}

.Layout{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80%;
  padding-bottom: $metrics-s;
  
  @include media-tablet {
    display: inherit;
    min-width: inherit;
  }
}

.MobileLayout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.ButtonsLayout {
  height: 270px;

  @include media-mobile {
    height: 300px;
  }

  @include media-tablet {
    height: 300px;
  }

  @include media-small-desktop {
    height: 310px;
  }
}

.Buttons {
  @include grid-column(12);
  transform: translateX(-7px);
  z-index: 1;
  margin: $metrics-s 0 $metrics-s;
}

.ButtonGap {
  margin-right: $metrics-s;
  margin-top: $metrics-l;
  @include media-mobile {
    margin-top: 0;
  }
}

.ButtonMobileGapRight {
  margin: $metrics-l 0 0;
  @include media-tablet {
    margin:  $metrics-xl+$metrics-s $metrics-xs 0 $metrics-xs;
  }
  @include media-small-desktop {
    margin-top: 0;
  }
}

.ButtonMobileGapLeft {
  margin:  $metrics-l $metrics-xs 0  $metrics-xs;
  @include media-tablet {
  margin:  $metrics-xl+$metrics-s $metrics-xs 0  $metrics-xs;
  }
  @include media-small-desktop {
    margin-top: 0;
  }
}