@import '~style/common';

.MainContainer {
  position: relative;
}

.AssetContainer {
  position: absolute;
  height: 100%;
  width: 100%;
}

.BackgroundAsset {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Container {
  position: relative;
  padding-top: $metrics-s;
  padding-bottom: $metrics-m;

  @include media-small-desktop {
    padding-top: $metrics-l;
    padding-bottom: $metrics-xxl;
  }
}

.Header {
  @include grid-column(12);
  float: none;
  margin-bottom: $metrics-s;
}

.MainHeading {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-M;
  margin-top: $metrics-xs;
  text-align: start;
  margin-bottom: $metrics-s;

  @include media-small-desktop {
    @include Font-Head-XL;
    margin: $metrics-xl 0 $metrics-l;
    text-align: center;
  }
}

.FirstColumn {
  @include grid-column(3, $mobile-grid);
  padding-bottom: $metrics-s;
  @include media-small-desktop {
    @include grid-column(4);
    padding-bottom: 0;
  }
}

.Column {
  @include grid-column(3, $mobile-grid);
  padding-bottom: $metrics-s;
  padding-top: $metrics-s;
  overflow: hidden;
  transition: height 0.5s ease-in-out;

  @include media-small-desktop {
    @include grid-column(4);
    height: auto !important;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.ColumnHidden {
  height: $metrics-l;

  @include media-small-desktop {
    display: block;
    height: auto;
  }
}

.ColumnContent {
  margin-top: $metrics-s;
}

.CarouselArrow {
  position: absolute;
  right: 0;
  width: 100%;
  height: $metrics-l;

  @include media-small-desktop {
    display: none;
  }
}

.CarouselArrow i {
  position: absolute;
  right: $metrics-s;
  transform: rotate(45deg) translate(-16px, -2px);
  @include media-tablet {
    right: $metrics-m + $metrics-s;
  }
}

.CarouselArrowOpened i {
  transform: rotate(225deg) translate(10px, -4px);
}

.HorizontalLine {
  @include grid-column(3, $mobile-grid);
  float: none;

  @include media-small-desktop {
    display: none;
  }
}
