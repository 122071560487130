@import '~style/common';

.Container {
  padding: $metrics-s 0;

  @include media-tablet {
    padding:  $metrics-l 0;
  }
}
.BigHeading {
  @include grid-column(3, $mobile-grid);
  @include Font-Head-M;
  margin-top: $metrics-xs;
  text-align: start;
  margin-bottom: $metrics-s;
  margin-left: $metrics-s;
  text-transform: uppercase;

  @include media-small-desktop {
    @include Font-Head-M;

  }
}
.EmployeeWrapper{
  margin-left: 30px;
  display: flex;
  flex-flow: column;
  width: 100%;
  @include media-tablet {
    flex-flow: row;
  }
}

.InfoColumn{
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  color: #2D2E35;
  @include Font-Body;

  @include media-tablet {
    margin-left: 70px;
  }

  p{
    padding-bottom: 20px;
  }
  .EmployeeName{
    font-weight: bold;
  }
  a{
    text-decoration: none;
    color: #2D2E35;
  }
}

.PrimaryColor{
  color:  $color-primary;
}

.AssetContainer {
  height: 260px;
  width: 260px;
  margin-bottom: $metrics-s;

  @include media-tablet {
    margin-bottom: 0;
  }
}

.BackgroundAsset {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
  box-shadow: 0px 24px 41px #00000029;
}
