@import "~style/common";

.Container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 $metrics-s 0;
}

.Wrapper {
  display: flex;
  flex-flow: column;

  @include media-tablet {
    flex-flow: row;
  }
}
