@import "~style/common";

.ArticleContent {
  @include Font-Body;

  strong {
    font-weight: 500;
  }

  a {
    color: $color-primary;
    @include hover-opacity;
  }

  p {
    margin: $metrics-xs * 2 0;
  }

  h1 {
    @include Font-Head-N;
    @include media-tablet {
      @include Font-Head-L;
    }
  }

  h1 {
    margin-top: $metrics-m;
    margin-bottom: $metrics-s;

    @include media-tablet {
      margin-top: $metrics-l;
      margin-bottom: $metrics-m;
    }
  }

  h2,
  h3,
  h4 {
    margin-top: $metrics-s;
    margin-bottom: $metrics-xs;

    @include media-tablet {
      margin-top: $metrics-sm;
      margin-bottom: $metrics-s;
    }
  }

  h2,
  h3 {
    @include Font-Head-S;
    @include media-tablet {
      @include Font-Head-M;
    }
  }

  h4 {
    @include Font-Head-S;
  }

  ul {
    list-style: none; /* Remove default bullets */
    margin: $metrics-xs 0 $metrics-xs $metrics-s + $metrics-xs;
  }

  ol {
    padding-left: $metrics-s;
  }

  img {
    width: 100%;
    margin-top: $metrics-s;
    margin-bottom: -$metrics-xs;
  }

  ul {
    list-style: none;
    margin-left: $metrics-xs;

    li {
      margin: 0 $metrics-s $metrics-xs $metrics-xs;
      padding-left: 2 * $metrics-xs;
      position: relative;

      &:after {
        content: "";
        height: 6px;
        width: 6px;
        background: $color-primary;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: $metrics-xs;
        left: 0;
      }
    }
  }
}
