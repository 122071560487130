@import "~style/common";

.Column {
  @include grid-column(4);

  &:last-of-type {
    margin-right: 0;
  }
}

.HeaderImage {
  height: 130px;
}

.Header * {
  @include Font-Head-S;
  padding-right: $metrics-s/2;
  position: relative;
}

.Content {
  margin-top: $metrics-s;
}
